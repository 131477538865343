<template>
	<div class="ad-img-box" style="height:90px;">
		<div :id="'image-'+dataId" class="h-100">
			<el-popover
			  v-if="image.image"
			  width="400"
			  placement="left"
			  trigger="hover">
				<el-image :src="image.image" style="width:100%;height:auto;object-fit:cover;" />
				<el-image
					class="mr-1"
					style="width:90px;height:90px"
					slot="reference"
					:src="image.image"
					:preview-src-list="previewSrcList"
					@load="loadImage"
					>
				</el-image>
			</el-popover>
		</div>
		<div v-if="image.videoLink" class="video-box">
			<video controls width="160" height="90" @play="videoPlay">
			    <source :src="image.videoLink">
				Sorry, your browser doesn't support embedded videos.
			</video>
		</div>
	</div>
</template>

<script>
	import { uuid } from 'vue-uuid'
	export default {
		props: {
			image: {
				type: Object,
				default: (() => {})
			},
			images: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				dataId: uuid.v4()
			}
		},
		computed: {
			previewSrcList() {
				return this.images.map(v => v.image)
			}
		},
		methods: {
			loadImage() {
				document.querySelector(`#image-${this.dataId} .el-image.el-popover__reference`).style.width="unset"
			},
			videoPlay(event){
				const videos = document.querySelectorAll('video')
				Array.from(videos).forEach(function(el, index) {
					if (el !== event.target) {
						el.pause()
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.ad-img-box {
		height:90px;
		margin-right:10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		.video-box {
			width: 160px;
			height: 90px;
		}
	}
</style>
