<template>
	<el-dialog id="distinct-products-dialog" :visible.sync="dialogVisible" :before-close="handleClose" width="70%">
		<el-tabs :key="tabsKey" style="margin-top:-45px;" v-model="activeName">
			<el-tab-pane name="2" :label="`全部（${+stat.totalBlockedCount})`" :platform="platform">
				<products-tab-pane-content ref="p-2" :channel="channel" :blockingRule="blockingRule" @callback="callback" @updateTabs="updateTabs" :namespace="namespace"></products-tab-pane-content>
			</el-tab-pane>
			<el-tab-pane name="0" :label="`下架商品（${+stat.offlineCount})`" :platform="platform">
				<products-tab-pane-content ref="p-0" :channel="channel" :blockingRule="blockingRule" :reason="0" @callback="callback" @updateTabs="updateTabs" :namespace="namespace"></products-tab-pane-content>
			</el-tab-pane>
			<el-tab-pane name="1" :label="`7日已发布两次（${+stat.tooManyPublishedCount})`" :platform="platform">
				<products-tab-pane-content ref="p-1" :channel="channel" :blockingRule="blockingRule" :reason="1" @callback="callback" @updateTabs="updateTabs" :namespace="namespace"></products-tab-pane-content>
			</el-tab-pane>
		</el-tabs>
	</el-dialog>
</template>

<script>
	import common from '@/common/mixins/common.js'
	import ProductsTabPaneContent from '../tab-pane/products-tab-pane-content.vue'
	import { uuid } from 'vue-uuid';
	export default {
		mixins: [common],
		components: {
			ProductsTabPaneContent
		},
		props: {
			platform: {
				type: String,
				default: 'fb'
			},
			visible: {
				type: Boolean,
				default: false
			},
			channel: {
				type: String,
				default: ''
			},
			blockingRule: {
				type: Boolean,
				default: false
			},
			namespace: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				activeName: '',
				stat: {
					totalBlockedCount: 0,
					offlineCount: 0,
					tooManyPublishedCount: 0
				},
				tabsKey: undefined
			}
		},
		computed: {
			dialogVisible: {
				get() {
					return this.visible
				},
				set(val) {
					this.$emit('update:visible', val)
				}
			}
		},
		watch: {
			dialogVisible(newVal, oldVal) {
				if (newVal) {
					this.tabsKey = uuid.v4()
					this.activeName = '2'
					this.products = {
						totalBlockedCount: 0,
						offlineCount: 0,
						tooManyPublishedCount: 0
					}
				} else {
					this.activeName = ''
				}
			},
			activeName(newVal, oldVal) {
				if (this.dialogVisible) {
					this.$nextTick(() => {
						this.$refs[`p-${newVal}`].reloadData()
					})
				}
			}
		},
		methods: {
			callback() {
				Object.keys(this.$refs).forEach(k => {
					if (/p-\d/.test(k)) {
						this.$refs[k].reloadData()
					}
				})
			},
			updateTabs(stat) {
				this.stat = {
					...this.stat,
					...stat
				}
			}
		}
	}
</script>

<style lang="scss">
	#distinct-products-dialog {
		.el-dialog__header {
			button {
				z-index: 10;
			}
		}
	}
	
</style>
