<template>
	<el-dialog :visible.sync="dialogVisible" :show-close="false" :before-close="handleClose" width="1000px">
		<div class="d-flex flex-row align-items-center" style="margin-top:-30px;">
			<alert class="mr-2" style="flex-grow:1;">
				{{data|adsCount}}{{data|adSetsCount}}{{data|unUsedSpu}}
			</alert>
			<el-button style="flex-shrink:0;" @click="dialogVisible=false" size="mini">取 消</el-button>
			<el-button style="flex-shrink:0;" type="primary" size="mini" :disabled="data|emptyAdSets" @click="$emit('callback')">确认发布</el-button>
		</div>
		<el-form class="mt-3" label-position="left">
			<el-row :gutter="20">
				<el-col v-if="showOptions" :span="8">
					<el-form-item  label="Ad Set开始时间:" label-width="120px">
						<el-date-picker
							v-model="data.startAt"
							type="datetime"
							value-format="timestamp"
							size="mini"
							clearable
							class="w-100"
							>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col v-if="showOptions" :span="8">
					<el-form-item label="归因窗口:" label-width="80px">
						<el-select v-model="data.attribution" :clearable="false" size="mini" class="w-100">
							<el-option v-for="item in attributions" :label="item.label" :value="item.key"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="发布状态:" label-width="80px">
						<el-switch
						  v-model="data.status"
						  active-value="ACTIVE"
						  inactive-value="PAUSED"
						  active-text="ACTIVE"
						  inactive-text="PAUSED">
						</el-switch>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		
		<el-table :data="tableData" style="width: 100%;" class="mt-3" row-key="ad_id" default-expand-all grouped="true">
			<el-table-column prop="campaignName" label="campaign" min-width="200">
			</el-table-column>
			<el-table-column prop="name" :label="platform === 'fb' ? 'ad set' : 'ad group'" min-width="200">
			</el-table-column>
			<el-table-column :label="columnLabel2" min-width="300">
				<template slot-scope="scope">
					<div v-if="scope.row.images" class="ad-medias-box">
						<div class="d-flex flex-row align-items-center" style="width:max-content;">
							<ads-preview-media v-for="image in scope.row.images" :image="image" :images="scope.row.images"></ads-preview-media>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
	</el-dialog>
</template>

<script>
	import common from '@/common/mixins/common.js'
	import Lodash from 'lodash'
	import AdsPreviewMedia from './ads-preview-media.vue'
	export default {
		mixins: [common],
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				default: (()=>{})
			},
			columnLabel2: {
				type: String,
				default: '广告图组'
			},
			platform: {
				type: String,
				default: 'fb'
			}
		},
		components: {
			AdsPreviewMedia
		},
		computed: {
			dialogVisible: {
				get() {
					return this.visible
				},
				set(val) {
					this.$emit('update:visible', val)
				}
			},
			tableData() {
				try {
					const adsets = Lodash.cloneDeep(this.data.adSets || [])
					return adsets.map((adset, index0) => {
						const { ads }= adset
						const firstAd = ads.shift()
						const { id, images } = firstAd
						return {
							...adset,
							ad_id: id + `-${index0}-0`,
							images,
							children: ads.map(({id, images}, index1) => {
								return {
									ad_id: id + `-${index0}-${index1 + 1}`, 
									images
								}
							})
						}
					}) 
				} catch { 
					return []
				}
			},
			showOptions() {
				return this.data.startAt !== undefined && typeof(this.data.attribution) === 'string'
			},
			attributions() {
				return [{
					label: '1 day click',
					key:'1D_CLICK',
					value: {clickThroughWindowDays:1, viewThroughWindowDays:0}
				}, {
					label: '7 days click',
					key: '7D_CLICK',
					value: {clickThroughWindowDays:7, viewThroughWindowDays:0}
				}, {
					label: '1 day click or view',
					key: '1D_CLICK_OR_VIEW',
					value: {clickThroughWindowDays:1, viewThroughWindowDays:1}
				}, {
					label: '7 days click or 1 day view',
					key: '7D_CLICK_OR_1D_VIEW',
					value: {clickThroughWindowDays:7, viewThroughWindowDays:1}
				}]
			}
		},
		filters: {
			adSetsCount(data) {
				try {
					return `，预计发布${data.adsCount}条ads`
				} catch {}
			},
			adsCount(data) {
				try {
					return `共生成${data.adSetsCount}组adset`
				} catch {}
			},
			unUsedSpu(data) {
				try {
					if (data.unUsedSpu.length > 0) {
						const unUsedSpu = data.unUsedSpu.join(', ')
						return `，未使用商品: ${unUsedSpu}`
					}
				} catch {}
			},
			emptyAdSets(data) {
				try {
					return data.adSets.length === 0
				} catch {
					return true
				}
			}
		},
		methods: {
			updateAdImgBox(id) {
				document.querySelector(id).style.width = 'unset'
			},
			getOptionsBody() {
				const body = {status: this.data.status}
				try {
					const attribution = this.attributions.find(v => v.key === this.data.attribution).value
					body['clickThroughWindowDays'] = attribution['clickThroughWindowDays']
					body['viewThroughWindowDays'] = attribution['viewThroughWindowDays']
					if (this.data.startAt) {
						body['adSetStartAt'] = this.data.startAt /1000
					}
				} catch (e) {console.log(e)}
				return body
			}
		}
	}
</script>

<style lang="scss">
	.ad-medias-box {
		overflow-x: auto;
		padding-bottom: 5px;
		overflow-y: hidden;
	}
	.ad-medias-box::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 5px;
		height: 5px;
	}
</style>
