<template>
	<div>
		<el-button v-if="reason!==0" type="primary" size="mini" @click="removeBlock">去除屏蔽选款</el-button>
		<alert v-if="reason!==0" class="my-2">已选择<span
				class="d-inline-block mx-1">{{this.multipleSelection.length}}</span>个商品</alert>
		<el-table height="auto" :data="products" style="width:100%;" @selection-change="handleSelectionChange" max-height="500px">
			<el-table-column v-if="reason!==0" type="selection" width="55" :selectable="checkSelectable">
			</el-table-column>
			<el-table-column label="商品id" prop="spu" min-width="120">
			</el-table-column>
			<el-table-column prop="rule" label="选款规则" min-width="150">
			</el-table-column>
			<el-table-column label="品类" prop="category" min-width="150">
			</el-table-column>
			<el-table-column label="商品主图" min-width="100">
				<template slot-scope="scope">
					<div v-if="scope.row.image" class="products-tab-pane-img-box">
						<img :src="scope.row.image" alt="">
					</div>
				</template>
			</el-table-column>
			<el-table-column label="屏蔽规则" min-width="150">
				<template slot-scope="scope">
					{{scope.row.reason === 0 ? '下架' : '7日内已发布两次'}}
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
	</div>
</template>

<script>
	import page from '@/common/mixins/page.js'
	import Alert from '../alert/index.vue'
	export default {
		inject: ['adminLayout'],
		mixins: [page],
		components: {
			Alert
		},
		data() {
			return {
				products: [],
				fetchOnCreated: false,
				page: {
					current: 1,
					size: 10,
					sizes: [10, 30, 50],
					total: 0
				},
				isFullPage: true
			}
		},
		props: {
			platform: {
				type: String,
				default: 'fb'
			},
			channel: {
				type: String,
				default: ''
			},
			reason: {
				type: Number,
				default: 2
			},
			blockingRule: {
				type: Boolean,
				default: false
			},
			namespace: {
				type: String,
				default: ''
			}
		},
		methods: {
			checkSelectable(row) {
				return row.reason !== 0
			},
			removeBlock() {
				if (this.multipleSelection.length === 0) {
					return this.$showErrMsg('请先选择商品')
				}
				this.adminLayout.showLoading(true)
				const ids = this.multipleSelection.map(v => v.spu)
				this.$store.dispatch(`${this.namespace}/activeBlockedItems`, ids)
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})	
			},
			reloadData() {
				this.page.current = 1
				this.getList()
			},
			handleList(products) {
				this.products = products
			},
			async getListUrl() {
				const {current, size} = this.page
				const reason = this.reason === 2 ? null : this.reason
				const payload = {page: current, size , channel: this.channel, reason, blockingRule: this.blockingRule ? 1 : 0}
				return this.$store.dispatch(`${this.namespace}/getBlockedList`, payload)
			},
			handleRawData({totalBlockedCount, offlineCount, tooManyPublishedCount}) {
				this.$emit('updateTabs', {totalBlockedCount, offlineCount, tooManyPublishedCount})
			}
		}
	}
</script>

<style lang="scss">
	.products-tab-pane-img-box {
		width: 60px;
		height: 72px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
</style>
