<template>
	<el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
		<el-form ref="form" :model="form" label-position="left" :hide-required-asterisk="true" size="mini">
			<el-form-item label="发布类型:" prop="type" label-width="80px">
				<form-items-publishtypes v-model="form.publishType" :with-layout="false" add-class="w-100" :disabledItems="disabledItems" :platform="platform"></form-items-publishtypes>
			</el-form-item>
			<el-form-item label="指定国家:" prop="country" label-width="80px">
				<el-input v-model="form.country" placeholder="请输入,多个用,分割"></el-input>
			</el-form-item>
			<el-form-item label="指定账户:" prop="account" label-width="80px">
				<el-input v-model="form.account" placeholder="请输入,多个用,分割"></el-input>
			</el-form-item>
			<el-form-item label="指定campaign:" prop="campaign" label-width="120px">
				<el-input v-model="form.campaign" placeholder="请输入,多个用,分割"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer text-right">
			<el-button type="primary" size="mini" @click="previewAds">生成{{buttonTitle}}广告</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import common from '@/common/mixins/common.js'
	import {mapActions} from 'vuex'
	import FormItemsPublishtypes from '@/components/form-items/form-item-publishtypes.vue'
	export default {
		mixins: [common],
		components: {
			FormItemsPublishtypes
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			buttonTitle: {
				type: String,
				default: '轮播'
			},
			platform: {
				type: String,
				default: 'fb'
			}
		},
		data() {
			return {
				form: {
					publishType: '',
					country: '',
					account: '',
					campaign: ''
				}
			}
		},
		computed: {
			dialogVisible: {
				get() {
					return this.visible
				},
				set(val) {
					this.$emit('update:visible', val)
				}
			},
			disabledItems() {
				return this.buttonTitle !== '单图' ? 'NEW_TEST,ALL' : 'ALL'
			}
		},
		watch: {
			dialogVisible(newVal, oldVal) {
				if (newVal) {
					this.form = {
						publishType: this.platform === 'fb' ? 'Normal' : 'UDA',
						country: '',
						account: '',
						campaign: ''
					}
					this.$nextTick(() => {
						this.$refs['form'].clearValidate()
					})
				}
			}
		},
		methods: {
			previewAds() {
				this.$refs['form'].validate(v => {
					if (v) {
						this.$emit('callback', this.form)
					}
				})
			}
		}
	}
</script>

<style>
</style>
